<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="dialog_detail_title">Report Errors</div>
                <div class="detail_cont_text">
                    Report Errors allows users to communicate with us to solve any data issues and improve data quality.
                    <br />
                    <p>1.If you find any data problems or have a question about this data</p>
                    <p>2.If you want to contribute company information to improve this data</p>
                    <p>3.If you choose not to disclose your own company information that you might consider private</p>
                    <p>4.If you have any constructive suggestions for us to improve services</p>
                    All comments submitted are subject to data verification. Users must complete your own user profile as our representative may contact you for authentication and verification of your company any rights to represent your company.
                </div>
                <div class="flex_center_between_box phoen_center_flex_warp" style="justify-content: center !important; margin-top: 20px">
                    <el-button class="btn_export" @click="newDialogtwo = true">Next</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 点击阅读同意弹出 -->
        <el-dialog :visible.sync="newDialogtwo" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="dialog_detail_title">Report Errors</div>
                <h1 class="dialog_detail_check" style="margin-bottom: 20px">
                    <el-checkbox v-model="checked">I am an employee of {{ companyName }} Company</el-checkbox>
                </h1>
                <div class="add_list">
                    <el-tabs type="card" class="tab_box" v-model="tabName">
                        <el-tab-pane label="Report my company" name="Reportmycompany" class="report_box">
                            <div class="cont_item" @click="finDialog = true,form.uploadFiles=[],filesList=[]">Update data</div>
                            <div class="cont_item" @click="reportDialog = true,form.uploadFiles=[],filesList=[]">Report information errors</div>
                        </el-tab-pane>
                        <el-tab-pane label="Report others company" name="Reportotherscompany" class="report_box">
                            <div class="cont_item" @click="finDialog = true,form.uploadFiles=[],filesList=[]">Update data</div>
                            <div class="cont_item" @click="reportDialog = true,form.uploadFiles=[],filesList=[]">Report information errors</div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-dialog>
        <!-- 点击 Update data 后弹出 -->
        <el-dialog :visible.sync="finDialog" center :close-on-click-modal="false" custom-class="dialog_lg" :append-to-body="true">
            <div class="detail_cont">
                <div class="fin_title">Financial</div>
                <el-form ref="form" :model="form" label-width="184px" class="form_box" label-position="left">
                    <div class="flex_box flex_boxtwo">
                        <el-form-item label="Please provide more info:" class="fin_top_item">
                            <el-select v-model="form.pleaseProvideMoreInfo" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in provideOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Year:" class="fin_top_year">
                            <el-select v-model="form.year" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="fin_cont_box flex_center_between_box phoen_center_flex_warp">
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total Current Assets:">
                                    <el-input v-model="form.totalCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Cash & Bank:">
                                    <el-input v-model="form.cashBank"></el-input>
                                </el-form-item>
                                <el-form-item label="Account receivable:">
                                    <el-input v-model="form.accountReceivable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances to Suppliers:">
                                    <el-input v-model="form.advancesToSuppliers"></el-input>
                                </el-form-item>
                                <el-form-item label="Inventory:">
                                    <el-input v-model="form.inventory"></el-input>
                                </el-form-item>
                                <el-form-item label="To be appointed expense:">
                                    <el-input v-model="form.toBeAppointedExpense"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total non-current assets:">
                                    <el-input v-model="form.totalNonCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term investment:">
                                    <el-input v-model="form.longTermInvestment"></el-input>
                                </el-form-item>
                                <el-form-item label="Fixed assets net value:">
                                    <el-input v-model="form.fixedAssetsNetValue"></el-input>
                                </el-form-item>
                                <el-form-item label="Projects under construction:">
                                    <el-input v-model="form.projectsUnderConstruction"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term to be appointed expenise:">
                                    <el-input v-model="form.longTermToBeAppointedExpenise"></el-input>
                                </el-form-item>
                                <el-form-item label="Deferred tax debit:">
                                    <el-input v-model="form.deferredTaxDebit"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total current liabilities:">
                                    <el-input v-model="form.totalCurrentLiabilities"></el-input>
                                </el-form-item>
                                <el-form-item label="Account payable:">
                                    <el-input v-model="form.accountPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances from clients:">
                                    <el-input v-model="form.advancesFromClients"></el-input>
                                </el-form-item>
                                <el-form-item label="Salaries payable:">
                                    <el-input v-model="form.salariesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Taxes payable:">
                                    <el-input v-model="form.taxesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Other accounts payable:">
                                    <el-input v-model="form.otherAccountsPayable"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total equity:">
                                    <el-input v-model="form.totalEquity"></el-input>
                                </el-form-item>
                                <el-form-item label="Paid up capital:">
                                    <el-input v-model="form.paidUpCapital"></el-input>
                                </el-form-item>
                                <el-form-item label="Capital reserves:">
                                    <el-input v-model="form.capitalReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Surplus reserves:">
                                    <el-input v-model="form.surplusReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Undistrbuted porfit:">
                                    <el-input v-model="form.undistrbutedPorfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Total assets:">
                                    <el-input v-model="form.totalAssets"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu last">
                                <el-form-item label="Turnover:">
                                    <el-input v-model="form.turnover"></el-input>
                                </el-form-item>
                                <el-form-item label="Cost of goods sold:">
                                    <el-input v-model="form.costOfGoodsSold"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales tax:">
                                    <el-input v-model="form.salesTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Gross profit:">
                                    <el-input v-model="form.grossProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Other operating profils:">
                                    <el-input v-model="form.otherOperatingProfils"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales expense:">
                                    <el-input v-model="form.salesExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Management expense:">
                                    <el-input v-model="form.managementExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Finance expense:">
                                    <el-input v-model="form.financeExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Operating profit:">
                                    <el-input v-model="form.operatingProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Non-operating income:">
                                    <el-input v-model="form.nonOperatingIncome"></el-input>
                                </el-form-item>
                                <el-form-item label="Profit before tax:">
                                    <el-input v-model="form.profitBeforeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Income tax:">
                                    <el-input v-model="form.incomeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Net income:">
                                    <el-input v-model="form.netIncome"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="fin_title">Business</div>
                    <div class="bus_box">
                        <el-form-item label="No.of employees:">
                            <el-input v-model="form.noOfEmployees"></el-input>
                        </el-form-item>
                        <el-form-item label="Core management:">
                            <el-input v-model="form.coreManagement"></el-input>
                        </el-form-item>
                        <el-form-item label="Core business activites:">
                            <el-input v-model="form.coreBusinessActivites" type="textarea" :rows="5"></el-input>
                        </el-form-item>
                    </div>
                    <div class="bus_box2">
                        <el-form-item label="Int' Business ">
                            <el-select v-model="form.intBusiness" placeholder="Select">
                                <el-option :label="item" :value="item" v-for="(item, index) in intBusOptions" :key="index"></el-option>
                            </el-select>
                            <el-input class="m_l" v-model="form.intBusiness1" placeholder="Please describe your activites"></el-input>
                            <el-input class="m_l" v-model="form.activites2" placeholder="Please describe your activites"></el-input>
                            <el-input class="m_l" v-model="form.intBusiness3" placeholder="Please list countries you do busine"></el-input>
                        </el-form-item>
                        <el-form-item label="Core competency :">
                            <el-checkbox-group v-model="form.coreCompetency" class="check_box">
                                <el-checkbox v-for="competency in CompetencyList" :label="competency" :key="competency">{{ competency }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                    <el-form-item label="Business outlook:" class="out_item">
                        <el-select v-model="form.businessOutlook" placeholder="Select">
                            <el-option :label="item" :value="item" v-for="(item, index) in busOptions" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Upload files:">
                        <el-upload class="upload-demo" action="fakeaction" ref="uploadFile" :http-request="hanldUploadFile" :before-remove="handleRemove"   v-model="form.uploadFiles" :file-list="filesList">
                            <el-button slot="trigger">Upload</el-button>
                        </el-upload>
                    </el-form-item>
                    <div class="btn_box">
                        <el-button class="btn_export" @click="onSubmit()">Submit</el-button>
                        <el-button class="btn_cancel" @click="cancel()">Cancel</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <!-- 点击 Report information errors 后弹出 -->
        <el-dialog :visible.sync="reportDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont report_box">
                <div class="dialog_detail_title">Data Problems</div>

                <div class="rep_list">
                    <el-form ref="reportForm" :model="reportForm" class="report_form">
                        <div class="report_item">
                            <div v-for="(report, index) in addReportList" :key="index">
                                <div class="add_btn" @click="addForReport()" v-show="index == 0"></div>
                                <div class="del_btn" @click="delForReport(index)" v-show="index > 0"></div>
                                <div class="report_form_box">
                                    <el-form-item label="Data Problems:">
                                        <el-select v-model="report.subject" placeholder="Select" @change="subjectChange(report.subject)">
                                            <el-option :label="item" :value="item" v-for="(item, index) in subjectOptions" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Correct Information:">
                                        <el-input v-model="report.demand" type="textarea" class="limit_box" maxlength="500" @input="inputChange(report.demand)" show-word-limit></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="Upload files:">
                                        <el-upload class="upload-demo"  :limit="3" action="fakeaction" ref="uploadFile" :on-exceed="handleExceed" :http-request="hanldUploadFile" :before-remove="handleRemove" v-model="form.uploadFiles" :file-list="filesList">
                                            <el-button slot="trigger" >Upload</el-button>
                                        </el-upload>
                                    </el-form-item> -->
                                    <el-form-item label="Upload files:">
                                        <el-upload class="upload-demo"  :limit="3" action="fakeaction" ref="uploadFiletwo" :on-exceed="handleExceed" :http-request="params => hanldUploadFiletwo(params,index)" :before-remove="(params,list)=>handleRemovetwo(params,list,index)" v-model="report.uploadFiles" :file-list="report.filesList">
                                            <el-button slot="trigger" >Upload</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="flex_center_between_box phoen_center_flex_warp">
                                <el-button class="btn_export" :disabled="disabledReport" @click="onSubmitReport()">Submit</el-button>
                                <el-button class="btn_cancel" @click="cancelReport()">Cancel</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getReportError, getCompanySnap, getUpdateDataCompare, uploadFile, deleteFile } from '@/api/companyApi.js';
import crypto from '../utils/crypto';
export default {
    props: {
        showReport: {
            type: Boolean,
            default: false,
        },
        powerStatus: {
            type: Boolean,
            default: false,
        },
        companynameen: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            // newDialog: true,
            tabName: 'Reportmycompany',
            finDialog: false,
            reportDialog: false,
            newDialogtwo: false,
            form: {
                pleaseProvideMoreInfo: '',
                year: new Date().getFullYear(),
                totalCurrentAssets: '',
                cashBank: '',
                accountReceivable: '',
                advancesToSuppliers: '',
                inventory: '',
                toBeAppointedExpense: '',
                totalNonCurrentAssets: '',
                longTermInvestment: '',
                fixedAssetsNetValue: '',
                projectsUnderConstruction: '',
                longTermToBeAppointedExpenise: '',
                deferredTaxDebit: '',
                // liabilities: '',
                totalCurrentLiabilities:'',
                accountPayable: '',
                advancesFromClients: '',
                salariesPayable: '',
                taxesPayable: '',
                otherAccountsPayable: '',
                totalEquity: '',
                paidUpCapital: '',
                capitalReserves: '',
                surplusReserves: '',
                undistrbutedPorfit: '',
                totalAssets: '',
                turnover: '',
                costOfGoodsSold: '',
                salesTax: '',
                grossProfit: '',
                otherOperatingProfils: '',
                salesExpense: '',
                managementExpense: '',
                financeExpense: '',
                operatingProfit: '',
                nonOperatingIncome: '',
                profitBeforeTax: '',
                incomeTax: '',
                netIncome: '',
                noOfEmployees: '',
                coreManagement: '',
                coreBusinessActivites: '',
                intBusiness3: '',
                intBusiness1: '',
                intBusiness2: '',
                intBusiness: '',
                coreCompetency: [],
                businessOutlook: '',
                uploadFiles: [],
              
                // filesList:[]
            },
            type: '701',
            filesList: [],
            provideOptions: ['CNY', 'EURO', 'USD'],
            yearOptions: [], //['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008'],
            busOptions: ['Good', 'Not optimistic', 'Unsure'],
            CompetencyList: ['Patent', 'Trademark', 'Copyright', 'Software copyright', 'High-tech'],
            intBusOptions: ['Yes', 'No'],
            subjectOptions: ['COMPANY SNAPSHOT','COMPANY PROFILE','MANAGEMENT', 'OWNERSHIP', 'BUSINESS OPERATIONS', 'PERFORMANCE','IMPORT&EXPORT'],
            disabledReport1: true,
            disabledReport2: true,
            disabledReport: true,
            reportForm: { subject: '', demand: '' },
            addReportList: [{ subject: '', demand: '' ,uploadFiles: [],filesList:[]}],
            checked: false,
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.showReport;
            },
            set(newValue) {},
        },
        companyName: {
            get() {
                return this.companynameen;
            },
            set(newValue) {},
        },
    },
    created() {
        this.getYears();
    },
    methods: {
        // 文件上传限制
        handleExceed(){
            this.$message.warning(`The current limit is to select 3 files, with a total of ${this. form. uploadFiles. length+1} files selected`);
        },
        // 限制文件上传大小
    //     handleBeforeUpload(file){
    //         handleBeforeUpload(file){
    //   const isSizeValid = file.size / 1024 / 1024 < 5; // 5MB
    //   if (!isSizeValid) {
    //     this.$message.error("文件大小不能超过5MB");
    //   }
    //   return isSizeValid;
    // }
        // },
        async setData() {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            let companyName = '';
            getCompanySnap('id3a=' + id3a + '&companyCountry=' + companyCountry).then(async res => {
                //解密
                let result = JSON.parse(crypto.decrypt(res));
                if (result && result.code) {
                    companyName = result.data.base.nameEn;
                    // console.log(`this.form`, this.form);
                    const params = {
                        companyName: companyName,
                        companyId: id3a,
                        demand: JSON.stringify(this.form),
                        type: this.checked ? 701 : 7,
                    };
                    let res = await getUpdateDataCompare(params);
                    if (res && res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                    }
                }
            });
        },
        //获取年 2008-2023
        getYears() {
            const loadYear = new Date().getFullYear();
            const n = loadYear - 2007;
            for (var i = 0; i < n; i++) {
                const year = loadYear - i;
                this.yearOptions.push(year);
            }
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeReportDialog', !this.newDialog);
        },
        //删除文件
        handleRemove(file,fileList) {
            let index = fileList.findIndex(item => item.name == file.name);
            const str = this.form.uploadFiles[index];
            let i = str.lastIndexOf('/');
            let result = str.substr(i + 1, str.length);
     
          this.form.uploadFiles.splice(index, 1)
        //   this.filesList.splice(index, 1)
         
            let params = { fileName: result };
            deleteFile(params).then(res => {
                if (res && res.code) {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'success',
                    });
                }
            });
        },
        handleRemovetwo(file, fileList,inde) {
            let index = fileList.findIndex(item => item.name == file.name);
            const str =  this.addReportList[inde].uploadFiles[index];
            let i = str.lastIndexOf('/');
            let result = str.substr(i + 1, str.length);
        this.addReportList[inde].uploadFiles.splice(index, 1)
       
        this.addReportList[inde].filesList.splice(index, 1)
            let params = { fileName: result };
            deleteFile(params).then(res => {
                if (res && res.code) {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'success',
                    });
                }
            });
        },
        //上传文件
        hanldUploadFile(params) {
            let file = params.file;
            let form = new FormData();
            form.append('file', file);
            uploadFile(form).then(res => {
                if (res && res.code) {
                    this.form.uploadFiles.push(res.data);
                    // this.form.filesList.push({name:file.name,url:res.data});
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'error',
                    });
                    let uid = file.uid;
                    let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid);
                    this.$refs.uploadFile.uploadFiles.splice(idx, 1);
                    // this.form.filesList.splice(idx, 1)
             
                }
            });
        },
        hanldUploadFiletwo(params,index) {
        
            let file = params.file;
            let form = new FormData();
            form.append('file', file);
            uploadFile(form).then(res => {
                console.log('uploadFiles', res);
                if (res && res.code) {
                    this.form.uploadFiles.push(res.data);
                    // this.form.filesList.push({name:file.name,url:res.data});
            
                    this.addReportList[index].uploadFiles.push(res.data);
                    this.addReportList[index].filesList.push({name:file.name,url:res.data});
                
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'error',
                    });
                    let uid = file.uid;
                    let idx = this.$refs.uploadFiletwo.uploadFiles.findIndex(item => item.uid === uid);
                    this.$refs.uploadFiletwo.uploadFiles.splice(idx, 1);
                    // this.form.filesList.splice(idx, 1)
             
                }
            });
        },
        //提交update
        onSubmit() {
            // console.log('Report update- this.powerStatus', this.powerStatus);
            if (!this.powerStatus) {
                let type = 1;
                this.$parent.getOrdersAdd(type).then(res => {
                    if (res) {
                        this.newDialog = false;
                        this.finDialog = false;
                        this.setData();
                    }
                });
            } else {
                this.newDialog = false;
                this.finDialog = false;
                this.setData();
            }
        },
        //取消update
        cancel() {
            this.finDialog = false;
        },

        subjectChange(val) {
            val.length > 0 ? (this.disabledReport1 = false) : (this.disabledReport1 = true);
            if (this.disabledReport1 == false && this.disabledReport2 == false) {
                this.disabledReport = false;
            } else {
                this.disabledReport = true;
            }
        },
        inputChange(val) {
            val.length > 0 ? (this.disabledReport2 = false) : (this.disabledReport2 = true);
            if (this.disabledReport1 == false && this.disabledReport2 == false) {
                this.disabledReport = false;
            } else {
                this.disabledReport = true;
            }
        },
        //提交report
        onSubmitReport() {
            // console.log('Report- this.powerStatus', this.powerStatus);
            if (!this.powerStatus) {
                let type = 1;
                this.$parent.getOrdersAdd(type).then(res => {
                    if (res) {
                        this.hanldSubmitReport();
                    }
                });
            } else {
                this.hanldSubmitReport();
            }
        },
        hanldSubmitReport() {
            this.newDialog = false;
            this.reportDialog = false;

            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            let companyName = '';
            getCompanySnap('id3a=' + id3a + '&companyCountry=' + companyCountry).then(async res => {
                //解密
                let result = JSON.parse(crypto.decrypt(res));
                // console.log(this.checked);
                if (result && result.code) {
                    let list = this.addReportList;
                    let arr = [];
                    // console.log(`this.uploadFiles`, this.form.uploadFiles);
                    list.forEach(item => {
                        let obj = {};
                        obj = {
                            coId: result.data.base.regId,
                            companyId: id3a,
                            companyName: result.data.base.nameEn,
                            subject: item.subject,
                            // demand:JSON.stringify({ demand:item.demand,filesList:this.form.filesList}),
                            demand: item.demand,
                            attachment:JSON.stringify(item.uploadFiles),
                            type: this.checked ? 601 : 6,
                        };
                        arr.push(obj);
                    });
                    // console.log(`arr`, arr);
                    let res = await getReportError(arr);
                    if (res && res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                    }
                }
            });
        },
        //取消report
        cancelReport() {
            this.reportDialog = false;
        },
        //添加report表单
        addForReport() {
            const obj = { subject: '', demand: '',filesList:[],uploadFiles:[]};
            this.addReportList.push(obj);
            this.disabledReport = true;
            this.disabledReport1 = true;
            this.disabledReport2 = true;
        },
        //删除report表单
        delForReport(n) {
            this.addReportList.splice(n, 1);
        },
    },
};
</script>
<style scoped>
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 16px;
    display: none;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 28px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 2px 28px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 160px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/.el-tabs__content {
    width: 440px;
    height: 130px;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.cont_item {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    background: #f2f5f9;
    background-image: url(../assets/img/detail/btn_ic9.png);
    background-repeat: no-repeat;
    background-size: 8px 14px;
    background-position: 95% center;
    cursor: pointer;
}

.fin_title {
    font-family: 'Arial Bold';
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: #022955;
}
.form_box /deep/ .el-input__inner {
    width: 162px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_top_sel /deep/ .el-input__inner {
    width: 276px;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    font-size: 12px;
}
.fin_top_year /deep/.el-form-item__label,
.fin_top_item /deep/.el-form-item__label {
    width: auto !important;
}
.form_box /deep/.el-form-item__label {
    padding-right: 10px;
}
.fin_top_item /deep/ .el-form-item__content {
    margin-left: 174px !important;
}
.fin_top_year /deep/ .el-form-item__content {
    margin-left: 50px !important;
}
.fin_top_year {
    margin-left: 40px;
}
.form_box /deep/ .el-form-item__label {
    color: #022955 !important;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.fin_cont_box {
    margin-bottom: 10px;
}
.fin_modu {
    width: 355px;
    height: 320px;
    padding: 20px 5px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_modu.last {
    height: 661px;
}
.form_box /deep/ .el-form-item__content {
    line-height: 34px;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 37px;
    line-height: 16px;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
}
.bus_box /deep/ .el-textarea__inner,
.bus_box /deep/ .el-input__inner {
    width:900px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.out_item /deep/ .el-input__inner,
.bus_box2 /deep/ .el-input__inner {
    width: 214px;
    height: 34px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .m_l .el-input__inner {
    margin-left: 16px;
}
.form_box /deep/ .out_item {
    margin-bottom: 20px;
}
.form_box /deep/ .el-input__icon {
    height: auto;
}
.upload-demo /deep/ .el-button {
    width: 90px;
    height: 34px;
    padding: 8px 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    font-size: 14px;
}
.check_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.report_form /deep/ .el-form-item__label,
.check_box /deep/ .el-checkbox__label {
    font-size: 14px;
    color: #022955;
}
.check_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.check_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.check_box /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 5px;
    top: 0px;
    width: 6px;
}
.btn_box {
    text-align: right;
    margin-top: 24px;
    margin-bottom: 16px;
}
.btn_box button + button {
    margin-left: 40px;
}
.rep_list {
    padding-bottom: 10px;
}

.add_btn {
    background: #1290c9;
    background-image: url(../assets/img/detail/add_ic.png);
}

.del_btn {
    background: #f56b6b;
    background-image: url(../assets/img/detail/del_ic.png);
}
.del_btn,
.add_btn {
    float: right;
    width: 26px;
    height: 26px;
    margin-top: -32px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
}
.report_form_box {
    padding: 10px 10px 0;
    margin-top: 42px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.report_form /deep/ .el-input__inner {
    width: 420px;
    height: 34px;
    line-height: 34px;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 20px;
}
.limit_box /deep/ .el-textarea__inner {
    height: 150px;
}
.report_item {
    margin-top: 48px;
}
</style>
<style>
.dialog_lg.el-dialog {
    width: 1150px;
    /* max-height: 85vh; */
    background: #ffffff;
    border-radius: 20px;
}
.detail_cont .el-upload-list__item {
    display: flex;
}
.el-upload-list__item-status-label {
    display: inline-block;
}
.el-upload-list__item:hover .el-upload-list__item-status-label {
    display: none;
}
.detail_cont .el-upload-list__item-status-label,
.detail_cont .el-upload-list__item .el-icon-close {
    position: static;
}
.detail_cont .el-upload-list__item .el-icon-close {
    line-height: 26px;
}
.detail_cont_text {
    /* 解决英文截断问题 */
    word-break: keep-all;
    text-align: justify;
    font-size: 14px;
    color: #022955;
    line-height: 25px;
}
.detail_cont_text p {
    padding-left: 10px;
    background: #f2f5f9;
    margin: 5px 0px;
}
.dialog_detail_check .el-checkbox .el-checkbox__label {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 5px;
}
@media (max-width: 821px) {
  .report_form /deep/ .el-input__inner {
    width: 300px !important;
}
.phoen_center_flex_warp{
    flex-wrap: wrap;
}
.flex_boxtwo {
    flex-wrap: wrap;
}
.fin_modu {
    width: 100% !important;
}
.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
}

.bus_box /deep/ .el-input__inner {
    width: 100px !important;
    background: #f2f5f9;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.form_box /deep/ .el-input__inner {
    width: 100px !important;
    background: #f2f5f9;
}
}
</style>
